import { createMuiTheme } from '@material-ui/core/styles';

const input = {
  border: {
    color: 'rgba(0, 0, 0, 0.23);',
    colorHover: '#1a73e8',
  },
};

const error = {
  light: '#e57373',
  main: '#f44336',
  dark: '#d32f2f',
  contrastText: '#fff',
};
const success = {
  light: '#e57373',
  main: '#f44336',
  dark: '#d32f2f',
  contrastText: '#fff',
};

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    common: {
      black: '#333',
      white: '#fff',
    },
   
    primary: {
      main: '#1a73e8',
    },
    secondary: {
      main: '#a59774',
    },
    error: error,
    success: success,
    text: {
      primary: '#333333',
    },
    background: {
      default: '#f5f5f5',
      paper: '#fff',
    },
  },

  props: {
    MuiFormControl: {
      fullWidth: true,
    },
    MuiTextField: {
      fullWidth: true,
      style: {
        border: 1,
      },
    },
    MuiCircularProgress: {
      color: 'secondary',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },
        body: {
          margin: 0,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderWidth: 1,
          borderColor: input.border.colorHover,
        },
        '&:hover $notchedOutline': {
          borderColor: input.border.colorHover,
        },
        notchedOutline: {
          borderColor: input.border.color,
        },
      },
      adornedStart: {
        color: 'rgba(0, 0, 0, 0.54)',
        '&$error': {
          color: error.main,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          margin: 0,
          fontSize: '0.8em',
        },
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
});

export default theme;
