import { applyMiddleware, compose, createStore, Store } from 'redux';
import reduxThunk from 'redux-thunk';
import createReducer from './index';
interface InjectSore extends Store {
  injectReducer: (key, asyncReducer) => void;
  asyncReducers: object;
}

const isProduction = process.env.NODE_ENV === 'production';
const middleware = [reduxThunk];
let enhancer: any = null;

if (isProduction) {
  enhancer = compose(applyMiddleware(...middleware));
} else {
  const composeEnhancers =
    (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancer = composeEnhancers(applyMiddleware(...middleware));
}

function configureStore() {
  const store: InjectSore = createStore<any, any, any, any>(
    createReducer(),
    enhancer,
  );
  store.asyncReducers = {};

  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };

  return store;
}

const store: InjectSore = configureStore();

export default store;
