import ReactDOM from 'react-dom';
import loadable from 'utils/loadable';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from 'styles/theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import store from 'reducers/store';

const App = loadable(() => import(/* webpackChunkName: "app" */ './App'));

const Root = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CssBaseline />
        <App />
      </Provider>
    </ThemeProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
