import { makeStyles } from '@material-ui/core/styles';
interface Props {
  fullScreen?: boolean;
}
const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    minHeight: 350,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 350,
  },
  spinner: {
    height: 30,
    width: 30,
    display: 'inline-block',
    borderRadius: '100%',
    borderWidth: 5,
    borderStyle: 'solid',
    borderImage: 'initial',
    borderColor: '#999 #999 transparent',
    animation: '$effect 0.75s 0s infinite linear',
    background: 'transparent !important',
  },
  '@keyframes effect': {
    '0%': {
      transform: 'rotate(0deg) scale(1)',
    },
    '50%': {
      transform: 'rotate(180deg) scale(1)',
    },
    '100%': {
      transform: 'rotate(360deg) scale(1)',
    },
  },
});

const LoadingIndicator = ({ fullScreen = false }: Props) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root}`}>
      <div className={`${classes.spinner}`}></div>
    </div>
  );
};

export default LoadingIndicator;
