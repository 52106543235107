import { combineReducers } from 'redux';
export type Action = {
  payload: object;
  type: string;
};

export const TYPE_REDUX = 'dispatch_data';

export default function createReducer(asyncReducers = {}) {
  return combineReducers({
    ...asyncReducers,
  });
}
